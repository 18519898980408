<template>
	<div class="download-container">
		<h3>You file has started downloading...</h3>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import zipAndDownloadFiles from '../../../utils/zipAndDownloadFiles';
export default {
	data() {
		return {

		};
	},
	async mounted() {
		const data = await this.fetchSubmission(this.$route.params.submissionId);
		const { documents, reason } = data;
		if (!documents.length) return;
		const fileUrls = documents.map(({ document }) => document);
		zipAndDownloadFiles({ urls: fileUrls, texts: [reason], fileName: 'award-submission-attachment' });
	},
	methods: {
		...mapActions({
			fetchSubmission: 'awards/fetchSubmission',
		}),
	},
};
</script>
<style >
.download-container {
	text-align: center;
}
</style>