import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';
const  mime = require('mime-to-extensions')

const download = url => fetch(url).then(resp => resp.blob());

const downloadByGroup = urls => Promise.map(
  urls,
  async url => download(url),
);

const exportZip = (blobs, texts = [], fileName = '') => {
  const zip = JsZip();
  blobs.forEach((blob, i) => {
    const ext = mime.extension(blob.type);
    zip.file(`file-${i + 1}.${ext}`, blob);
  });
  texts.forEach((text, i) => {
    zip.file(`text-${i + 1}.txt`, text);
  });
  zip.generateAsync({ type: 'blob' }).then(zipFile => FileSaver.saveAs(zipFile, fileName));
};

const downloadAndZip = ({ urls, texts, fileName }) => downloadByGroup(urls, 5).then(blobs => exportZip(blobs, texts, fileName));
export default downloadAndZip;
